import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Modal, Button } from 'antd';

import JPYImage from '../../../static/images/about-us/leadership/jpy.jpg';
import CholoImage from '../../../static/images/about-us/leadership/cholo.jpg';
import ChaiImage from '../../../static/images/about-us/leadership/chai.jpg';
import DouglasImage from '../../../static/images/about-us/leadership/douglas.jpg';

import MobileBioComponent from './MobileBioComponent';

const topLeftRight = keyframes`
  from {
    width: 0;
    background: #fad127;
  }
  to {
    width: calc(100% - 20px);
    background: #fad127;
  }
`;

const topRightBottom = keyframes`
  0% {
    height: 0;
    background: #fad127;
  }
  100% {
    height: calc(100% - 20px);
    background: #fad127;
  }
`;
const bottomRightLeft = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: calc(100% - 20px);
    background: #fad127;
  }
`;

const bottomLeftTop = keyframes`
  0% {
    height: 0;
    background: #fad127;
  }
  100% {
    height: calc(100% - 20px);
    background: #fad127;
  }
`;

const StyledDivWrapper = styled.div`
  margin-top: 36px;
  max-width: 1366px;

  background: white;
  z-index: 5;
  position: relative;
  /* box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22); */
  padding-bottom: 24px;
  margin-right: 42px;
  margin-left: 42px;
  /* margin-top: -60px; */

  @media only screen and (max-width: 426px) {
    margin-right: auto;
    margin-left: auto;
    /* margin-top: unset; */
  }

  .bod-wrapper {
    margin-bottom: 50px;
    h2 {
      margin-top: 30px;
      /* text-align: center; */
      margin-bottom: 8px;
    }
    p {
      margin-bottom: 30px;
      font-size: 20px;
      line-height: 26px;
    }
  }

  .leadership-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .leadership-entry {
    padding: 20px;
    padding-bottom: 0px;
    h2 {
      margin-bottom: 0px;
      font-weight: 600;
      color: ${props => props.theme.primeBluePalette.original};
    }
    h4 {
      margin-bottom: 24px;
    }
  }
  .leadership-sub-entry {
    img {
      margin-bottom: 4px;
    }
    .leadership-sub-entry-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      h3 {
        margin-bottom: 6px;
        color: ${props => props.theme.primeBluePalette.original};
      }
    }
  }
  .content {
    padding: 0px 20px;
    text-align: left;
  }
  .content-smaller {
    padding: 0px 20px;
    text-align: left;
    p {
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 24px;
    }
  }
  .read-more-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    .read-more-button {
      font-weight: 600;
      letter-spacing: 2px;
    }
  }
  .hidden-col {
    padding-bottom: 0px !important;
    margin-bottom: 40px;
    border-bottom: 2px solid ${props => props.theme.primeBluePalette.original};
    :after {
      content: '';
      display: block;
      position: relative;
      top: 19px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 18px 18px 18px 18px;
      margin: 0 auto;
      border-color: ${props => props.theme.primeBluePalette.original};
      clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    }
  }
  .leadership-cards {
    margin-top: 50px !important;
  }

  @media only screen and (max-width: 1200px) {
    .bod-wrapper p {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 22px;
    }
  }
  @media only screen and (max-width: 800px) {
    .bod-wrapper h2 {
      margin-top: 0px;
    }
  }
  @media only screen and (max-width: 768px) {
    .image-jpy {
      margin-bottom: 0px;
    }
    .leadership-entry {
      padding-top: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

const StyledLeaderCard = styled.div`
  img {
    margin-bottom: 0;
  }
  .content {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: auto;
    overflow: hidden;
    /* box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.22); */
  }

  @media only screen and (min-width: 426px) {
    .content .content-overlay {
      background-color: rgba(0, 51, 102, 0.65);
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      -webkit-transition: all 0.4s ease-in-out 0s;
      -moz-transition: all 0.4s ease-in-out 0s;
      transition: all 0.4s ease-in-out 0s;
    }

    .content:hover .content-overlay {
      opacity: 1;
    }

    .content-details {
      position: absolute;
      /* text-align: center; */
      padding-left: 2em;
      padding-right: 2em;
      width: 100%;
      top: 50%;
      left: 50%;
      opacity: 1;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-transition: all 0.3s ease-in-out 0s;
      -moz-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
      text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.32);
    }
    .content:hover .content-details {
      top: 50%;
      left: 50%;
      opacity: 1;
    }
  }

  .content-image {
    width: 100%;
  }

  .content-details {
    position: absolute;
    /* text-align: center; */
    padding-left: 2em;
    padding-right: 2em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
  }

  .content-details h4 {
    color: ${props => props.theme.whitePalette.original};
    margin-bottom: 4px;
  }

  .content-details p {
    color: ${props => props.theme.whitePalette.original};
  }

  .fadeIn-bottom {
    top: 80%;
  }

  .content-title,
  p {
    margin-bottom: 0px;
  }
  .content-title {
    display: flex;
    align-items: center;
    color: white;
  }

  .content-text {
    margin-bottom: 10px;
    font-weight: 600;
  }

  .content-bio {
    max-height: 80px;
    overflow: hidden;
    font-size: 16px;
    line-height: 20px;
    position: relative;
    mask-image: linear-gradient(to bottom, black 60%, transparent 100%);
    margin-top: 16px;
  }
  .content-bio-read-more {
    color: white;
    margin-top: 8px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    cursor: pointer;
    text-decoration: underline;
  }

  .content-actions {
    font-size: 16px;
    margin-left: 8px;
    color: ${props => props.theme.primeYellowPalette.original} !important;
    :hover {
      opacity: 0.85;
    }
  }

  .mobile-info {
    display: none;
    visibility: hidden;
    @media only screen and (max-width: 426px) {
      display: block;
      visibility: visible;
      padding: 20px 10px 0 10px;
      /* text-align: center; */
    }

    .bod-name,
    .bod-pos,
    .bod-actions {
      line-height: 1.1;
    }

    .bod-name {
      /* font-size: 1.25em; */
      font-weight: bold;
      color: ${props => props.theme.primeBluePalette.original};
      margin-bottom: 2px !important;
      display: flex;
      align-items: center;
    }

    .bod-pos {
      margin-bottom: 10px;
      font-weight: 600;
    }

    .bod-actions {
      font-size: 16px;
      margin-left: 8px;
      color: ${props => props.theme.primeBluePalette.original} !important;
      :hover {
        opacity: 0.85;
      }
    }

    .content-text {
      margin-bottom: 10px;
    }
    .content-actions {
      font-size: 16px;
      color: ${props => props.theme.primeBluePalette.original};
    }
  }

  /**
Box outline animation
 */

  .box {
    position: relative;
    margin: 0 auto;
    /* border: 3px solid transparent; */
  }

  .top,
  .bottom,
  .left,
  .right {
    position: absolute;
    animation: 0.25s forwards;
    animation-timing-function: linear;
  }
  .top {
    top: 10px;
    left: 10px;
    height: 1px;
  }

  .right {
    top: 10px;
    right: 10px;
    width: 1px;
  }

  .bottom {
    bottom: 10px;
    right: 10px;
    height: 1px;
  }

  .left {
    bottom: 10px;
    left: 10px;
    width: 1px;
  }

  @media only screen and (min-width: 426px) {
    .content:hover {
      .top {
        animation-name: ${topLeftRight};
      }
      .right {
        animation-name: ${topRightBottom};
        animation-delay: 0.1s;
      }
      .bottom {
        animation-name: ${bottomRightLeft};
        animation-delay: 0.1s;
      }
      .left {
        animation-name: ${bottomLeftTop};
        animation-delay: 0.1s;
      }
    }
  }

  @media only screen and (max-width: 426px) {
    .content:hover {
      .box,
      .content-overlay,
      .content-details {
        opacity: 0 !important;
      }
    }
  }
`;

const PreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledModal = styled(Modal)`
  .modal-title,
  p {
    margin-bottom: 0px;
  }
  .modal-title {
    display: flex;
    align-items: center;
  }

  .modal-text {
    margin-bottom: 10px;
    font-weight: 600;
  }

  .modal-bio {
    font-size: 16px;
    line-height: 20px;
    position: relative;
    margin-top: 16px;
  }

  .modal-actions {
    font-size: 16px;
    margin-left: 8px;
    color: ${props => props.theme.primeBluePalette.original} !important;
    :hover {
      opacity: 0.85;
    }
  }
`;

function ManagementComponent() {
  const data = useStaticQuery(graphql`
    {
      allStrapiManagement {
        group(field: category) {
          nodes {
            name
            photo_alt
            photo_main
            position
            slug
            strapiId
            email_address
            category
            bio
          }
        }
      }
    }
  `);
  const [showJPYCopy, setJPYCopyVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const handleWebReadMore = node => {
    setModalContent(node);
    setModalVisible(true);
  };
  const hideBioModal = () => {
    setModalVisible(false);
  };

  return (
    <PreWrapper>
      <StyledDivWrapper>
        <Row type="flex" justify="center">
          <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={20}>
            {data.allStrapiManagement.group.map(group => (
              <div key={group.nodes[0].category} className="bod-wrapper">
                <Row type="flex" justify="center" gutter={[20, 20]}>
                  <Col xs={20} sm={12} md={8} lg={8} xl={8} xxl={8}>
                    <img alt="Jet Yu" src={JPYImage} className="image-jpy" />
                  </Col>
                  <Col
                    xs={23}
                    sm={22}
                    md={14}
                    lg={14}
                    xl={14}
                    xxl={14}
                    className="leadership-col"
                  >
                    <div className="leadership-entry">
                      <h2>Jet Yu</h2>
                      <h4>Founder and CEO</h4>
                    </div>
                    <div className="content">
                      <p>
                        Closing more than Php500 million-worth of real estate
                        deals by the age of 25, Jettson “Jet” Yu, young
                        entrepreneur and licensed real estate broker,
                        spearheaded and founded homegrown Filipino real estate
                        advisory firm, PRIME Philippines.
                      </p>
                      <p>
                        His goal was simple: “To challenge tradition and to
                        create better real estate solutions.”
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row type="flex" justify="center">
                  <Col
                    xs={23}
                    sm={22}
                    md={22}
                    lg={22}
                    xl={22}
                    xxl={22}
                    className="read-more-col"
                  >
                    <div className="content">
                      <p>
                        PRIME Philippines was founded in 2013 stemming from a
                        need to provide better real estate solutions in a
                        rapidly changing economy influenced by the millennial
                        market and technological advancements. In its 7 years of
                        existence, under the leadership of Jet, PRIME
                        Philippines has currently amassed a portfolio of over
                        PHP 30B, closed over 100 deals, and transacted in over
                        10 countries around the world.
                      </p>
                    </div>
                    {!showJPYCopy && (
                      <Button
                        size="large"
                        className="read-more-button"
                        onClick={() => setJPYCopyVisible(true)}
                      >
                        READ MORE
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row type="flex" justify="center">
                  <Col
                    xs={22}
                    sm={22}
                    md={22}
                    lg={22}
                    xl={22}
                    xxl={22}
                    className="hidden-col"
                  >
                    {showJPYCopy && (
                      <div className="content">
                        <p>
                          Among these are PHP 500 million worth of property
                          acquisition within Clark, Pampanga in the span of one
                          year, along with one of the largest single-ticket
                          investment transactions in Quezon City during the time
                          of its transaction. PRIME Philippines is consistently
                          within the top 5 local real estate consultancy firms
                          in terms of revenue since 2015. The company’s mission
                          is to be the first local real estate firm to expand
                          internationally and successfully showcase Filipino
                          real estate on a global stage.
                        </p>
                        <p>
                          As a testament of his success, Jet was recognized by
                          Asia Leaders Awards as Young CEO of the Year and Young
                          Businessman of the Year in 2019. PRIME Philippines was
                          also recognized by the city of Davao through its
                          Business Development and Entrepreneurship Award. This
                          came from efforts by Jet and his company to enable and
                          bring in businesses and investments to the city.
                        </p>
                        <p>
                          An innovator in the industry, Jet currently works with
                          various proptech firms and serves as either consultant
                          or investor, with the goal of improving and updating
                          how the real estate industry works. Jet remains in the
                          lookout for potential game changers and has appeared
                          as one of the investors in CNN’s The Final Pitch. With
                          his keen view of the future of real estate in mind,
                          major developer clients have tapped Jet’s expertise
                          with the aim of future proofing their real estate
                          developments and portfolios in this highly competitive
                          and millennial driven market.
                        </p>
                        <p>
                          Jet is also a member of the Urban Land Institute and
                          an avid advocate of nation building. He believes that
                          his part in the advocacy is through city enabling. Jet
                          has worked with the national government in uplifting
                          Marawi City post-battle through providing a market
                          study, suggesting plausible developments and
                          industries to empower, and bringing in potential
                          investors.
                        </p>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row type="flex" justify="center" gutter={[20, 20]}>
                  <Col
                    xs={20}
                    sm={10}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    className="leadership-sub-entry"
                  >
                    <img
                      alt="Cholo Florencio"
                      src={CholoImage}
                      className="image-jpy"
                    />
                    <div className="leadership-sub-entry-details">
                      <h3>Cholo Florencio</h3>
                      <h5>Vice President</h5>
                    </div>
                  </Col>
                  <Col xs={22} sm={22} md={16} lg={16} xl={16} xxl={16}>
                    <div className="content-smaller">
                      <p>
                        Since joining PRIME in 2014, he has taken various roles
                        in Business Development, Landlord Representation,
                        Leasing & Sales, and Research & Advisory. As a seasoned
                        real estate professional with over 15 years of
                        experience in the Philippines property market, he has
                        been involved in over 200 transactions with property
                        developers, land bankers, landlords, and occupiers,
                        helping them move forward through innovative & modern
                        real estate solutions.
                      </p>
                      <p>
                        With his expertise in deal negotiation, client
                        engagement, and property marketing, Cholo has been
                        instrumental in securing exclusive leasing agency
                        contracts with various commercial developments across
                        the Philippines. Prior to joining the company, he
                        created a career in real estate advisory where he
                        assisted local and multinational companies expand to
                        major CBDs, adding to his vast client network, now
                        spanning Finance, Manufacturing, IT-BPM, Government,
                        Professional Services, Gaming, Retail, Health &
                        Wellness, F&B, Petroleum, and other industries.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row type="flex" justify="center" gutter={[20, 20]}>
                  <Col
                    xs={20}
                    sm={10}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    className="leadership-sub-entry"
                  >
                    <img
                      alt="Chai Abaya"
                      src={ChaiImage}
                      className="image-jpy"
                    />
                    <div className="leadership-sub-entry-details">
                      <h3>Chai Abaya</h3>
                      <h5>Regional Head, VisMin</h5>
                    </div>
                  </Col>
                  <Col xs={22} sm={22} md={16} lg={16} xl={16} xxl={16}>
                    <div className="content-smaller">
                      <p>
                        With over 17 years of working in the industry she is a
                        widely-connected and highly-experienced real estate
                        practitioner currently leading PRIME Philippines’ VisMin
                        leasing, sales, and expansion. Her expertise in account
                        management and deal negotiation has helped Chai grow her
                        strong network in the field, with clients and partners
                        from a wide variety of industries including Real Estate,
                        Banking and Finance, Hotel, IT-BPO, and Government
                        Services.
                      </p>
                      <p>
                        Under her leadership, PRIME Philippines was established
                        as the leading consultancy firm in the Davao Region and
                        has since continued to increase the company&apos;s reach
                        within Mindanao. Prior to working for the company, Chai
                        dealt with property development and has handled the
                        Torre Lorenzo Devt. Corp&apos;s biggest township
                        development, Ciudades Business Park, in Davao City. She
                        has also helped San Miguel Corporation achieve an
                        impressive growth in their provincial operations for
                        over 15 years. Chai is also the past President of the
                        Chamber of Real Estate and Builders&apos; Associations
                        Inc. (CREBA) - Davao Chapter.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row type="flex" justify="center" gutter={[20, 20]}>
                  <Col
                    xs={20}
                    sm={10}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    className="leadership-sub-entry"
                  >
                    <img
                      alt="Douglas Louden"
                      src={DouglasImage}
                      className="image-jpy"
                    />
                    <div className="leadership-sub-entry-details">
                      <h3>Douglas Louden</h3>
                      <h5>Director for Hospitality & Leisure</h5>
                    </div>
                  </Col>
                  <Col xs={22} sm={22} md={16} lg={16} xl={16} xxl={16}>
                    <div className="content-smaller">
                      <p>
                        With over 35 years of real estate experience in the
                        Southeast Asian hotel sector, with focus on Hong Kong
                        and Vietnam, he has served a wide array of clients,
                        mostly from prominent hotel brands, providing strategic
                        and operations advisory. His expertise lies in hotel
                        operations advisory and asset management, having
                        successfully served as advisor for clients under the
                        Hyatt brand for opening and reopening.
                      </p>
                      <p>
                        Douglas&apos; extensive property management experience;
                        in both opening and operating branded and independent
                        hotels, golf courses, city properties and resorts, clubs
                        and marinas have all contributed to his vast expertise
                        in the various aspects of real estate, namely
                        hospitality, infrastructure, development, and
                        investments.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="center"
                  gutter={[20, 20]}
                  className="leadership-cards"
                >
                  {group.nodes.map(node => (
                    <Col
                      xs={18}
                      sm={12}
                      md={8}
                      lg={8}
                      xl={8}
                      xxl={8}
                      key={node.strapiId}
                    >
                      <StyledLeaderCard className="leader-card">
                        <div className="content">
                          <div className="content-overlay box">
                            <div className="top"></div>
                            <div className="right"></div>
                            <div className="bottom"></div>
                            <div className="left"></div>
                          </div>
                          <img
                            className="content-image"
                            src={node.photo_main}
                            alt={node.name}
                          />
                          <div className="content-details fadeIn-bottom">
                            <h3 className="content-title">
                              {node.name}{' '}
                              {node.email_address ? (
                                <a
                                  className="content-actions"
                                  href={`mailto:${node.email_address}`}
                                >
                                  <FontAwesomeIcon fixedWidth icon="envelope" />
                                </a>
                              ) : (
                                ''
                              )}
                            </h3>
                            <p className="content-text">{node.position}</p>
                            <p
                              className="content-bio"
                              dangerouslySetInnerHTML={{
                                __html: node.bio,
                              }}
                            />
                            <div
                              className="content-bio-read-more"
                              onClick={() => {
                                handleWebReadMore(node);
                              }}
                            >
                              READ MORE
                            </div>
                          </div>
                        </div>
                        <div className="mobile-info">
                          <h3 className="bod-name">
                            {node.name}{' '}
                            {node.email_address ? (
                              <a
                                className="bod-actions"
                                href={`mailto:${node.email_address}`}
                              >
                                <FontAwesomeIcon fixedWidth icon="envelope" />
                              </a>
                            ) : null}
                          </h3>
                          <div className="bod-pos">{node.position}</div>
                          <MobileBioComponent render={node.bio} />
                        </div>
                      </StyledLeaderCard>
                    </Col>
                  ))}
                </Row>
              </div>
            ))}
          </Col>
        </Row>
        <StyledModal
          visible={modalVisible}
          onCancel={hideBioModal}
          footer={null}
        >
          {modalContent ? (
            <>
              <h3 className="modal-title">
                {modalContent.name}{' '}
                {modalContent.email_address ? (
                  <a
                    className="modal-actions"
                    href={`mailto:${modalContent.email_address}`}
                  >
                    <FontAwesomeIcon fixedWidth icon="envelope" />
                  </a>
                ) : (
                  ''
                )}
              </h3>
              <p className="modal-text">{modalContent.position}</p>
              <p
                className="modal-bio"
                dangerouslySetInnerHTML={{
                  __html: modalContent.bio,
                }}
              />
            </>
          ) : (
            ''
          )}
        </StyledModal>
      </StyledDivWrapper>
    </PreWrapper>
  );
}

export default ManagementComponent;
