import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ManagementComponent from '../components/about-us/ManagementComponent';
import VideoBackgroundHeader from '../components/about-us/VideoBackgroundHeader';

// import OurHistoryComponent from '../components/about-us/OurHistoryComponent';
// import PlaceholderPageComponent from '../components/PlaceholderPageComponent';

const StyledDivWrapper = styled.div`
  /* display: flex; */
  padding-top: 89px;
  /* flex-direction: column; */
  /* align-items: center; */
  overflow: hidden;
  h2 {
    font-size: 2.5em;
    font-weight: bold;
  }

  .who-we-are-section {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    min-height: 200px;
    padding: 75px 0px;
    h2 {
      color: ${props => props.theme.primeBluePalette.original};
      font-size: 2.5em;
    }
    p {
      font-size: 1.25em;
      margin-bottom: 0;
      line-height: 1.25;
    }
  }
  .styled-divider {
    border-color: ${props => props.theme.primeBluePalette.original};
    border-width: 3px;
  }

  .row-wrapper {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
  }
`;

function LeadershipPage({ location }) {
  return (
    <Layout source="Leadership Page">
      <SEO
        title="Our Leadership"
        description="The Leading Commercial Real Estate Consultancy Firm in the Philippines."
        meta={[
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content:
              'Real Estate Service Provider in PH,Real Estate Service in the Philippines,Real Estate Service Firm in PH,Real Estate Service Firm in the Philippines',
          },
        ]}
      />
      <StyledDivWrapper>
        <VideoBackgroundHeader />

        <ManagementComponent />

        {/* <OurHistoryComponent /> */}
      </StyledDivWrapper>
    </Layout>
  );
}

LeadershipPage.propTypes = {
  location: PropTypes.object,
};

export default LeadershipPage;
