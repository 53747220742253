/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components';
import { Player, ControlBar } from 'video-react';
import { Row, Col } from 'antd';

import 'video-react/dist/video-react.css';

const VideoBackgroundHeaderWrapper = styled.div`
  .hero-video {
    position: relative;
    height: 65vh;
    max-height: 450px;
    @media only screen and (min-width: 911px) and (max-width: 1100px) {
      height: 50vh;
    }
    @media only screen and (min-width: 550px) and (max-width: 772px) {
      height: 40vh;
    }
    max-width: 1440px;
    margin: auto;
    overflow: hidden;
    .video-react-big-play-button.video-react-big-play-button-left {
      display: none;
    }
  }
  .video-react .video-react-poster {
    background-size: cover !important;
  }

  .hero-cta {
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.3);
    > div:first-child {
      font-size: 1.35em;
      line-height: 1.25em;
      text-align: right;
      padding: 16px;
      background-color: ${props => props.theme.primeBluePalette.original};
      color: ${props => props.theme.whitePalette.original};
      a {
        color: ${props => props.theme.primeYellowPalette.original};
        font-weight: bold;
        text-decoration: underline;
        :hover {
          opacity: 0.8;
        }
      }

      display: flex;
      align-items: flex-end;
      flex-direction: column;

      @media only screen and (max-width: 550px) {
        font-size: 16px;
        line-height: 1.1;
      }
      @media only screen and (min-width: 550px) and (max-width: 768px) {
        font-size: 1.25em;
        line-height: 1.4em;
      }
      @media only screen and (min-width: 768px) and (max-width: 1100px) {
        font-size: 1em;
        line-height: 1.2em;
      }
    }
    > div:last-child {
      font-size: 1.5em;
      line-height: 1;
      padding: 20px;
      color: ${props => props.theme.primeBluePalette.original};
      font-weight: bold;
      background-color: ${props => props.theme.whitePalette.original};
      display: flex;
      align-items: center;

      a {
        :hover {
          opacity: 0.9;
        }
      }
      @media only screen and (max-width: 550px) {
        font-size: 1.15em;
        justify-content: flex-end;
      }
      @media only screen and (min-width: 550px) and (max-width: 768px) {
        font-size: 1.15em;
        justify-content: flex-end;
      }
      @media only screen and (min-width: 768px) and (max-width: 1100px) {
        font-size: 1em;
      }
    }
  }

  .hero-title {
    color: ${props => props.theme.whitePalette.original};
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);

    span {
      color: ${props => props.theme.primeYellowPalette.original};
    }
  }

  .hero-description {
    color: ${props => props.theme.whitePalette.original};
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
    span {
      color: ${props => props.theme.bluePalette.original};
    }
  }
`;

const StyledVideoPlayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  height: 65vh;
  max-height: 450px;
  @media only screen and (min-width: 911px) and (max-width: 1100px) {
    height: 50vh;
  }
  @media only screen and (min-width: 550px) and (max-width: 772px) {
    height: 40vh;
  }
  width: 100%;
  z-index: -1;
  overflow: hidden;
  :after {
    background-color: ${props => props.theme.primeBluePalette.original};
    opacity: 0.7;
    /* background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(41,41,41,0.1) 100%); */
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    height: calc(100vh + 1px);
    width: calc(100% + 1px);
    z-index: 0;
  }
  video {
    transform: scale(1);
    transform-origin: 50% 80%;
    @media only screen and (min-width: 911px) and (max-width: 1100px) {
      transform: scale(1.3);
    }
    @media only screen and (min-width: 550px) and (max-width: 911px) {
      transform-origin: 50% 50%;
      transform: scale(1.5);
    }
  }

  @media only screen and (max-width: 550px) {
    .video-react {
      visibility: hidden;
      display: none;
    }
    :after {
      opacity: 0.5;
    }

    :before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background-image: url('../../images/home/homepage-mobile-cover.jpg');
      background-size: cover;
      background-position: center;
      z-index: -1;
      height: 100%;
      width: 100%;
    }
  }
`;

const StyledContentArea = styled.div`
  position: relative;
  height: 100%;
  > div {
    height: 100%;
    text-align: center;

    h1 {
      font-size: 3.25rem;
      margin-bottom: 22px;
    }
    p {
      font-size: 1.35rem;
      line-height: 1.25;
      font-weight: normal;
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 550px) {
    > div {
      text-align: center;

      h1 {
        font-size: 1.85em;
        margin-bottom: 22px;
      }
      p {
        font-size: 1em;
        line-height: initial;
        font-weight: normal;
      }
    }
  }
  @media only screen and (min-width: 550px) and (max-width: 768px) {
    > div {
      h1 {
        font-size: 2.5em;
      }
      p {
        font-size: 1.15rem;
        line-height: 1.15;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1100px) {
    > div {
      h1 {
        font-size: 2.5em;
      }
    }
  }
`;

function VideoHeaderBackgroundComponent() {
  return (
    <VideoBackgroundHeaderWrapper>
      <div className="hero-video">
        <StyledContentArea>
          <Row type="flex" justify="center" align="middle">
            <Col xs={20} sm={20} md={18} lg={18} xl={18}>
              <h1 className="hero-title">
                The <span>PRIME</span> Foundation
              </h1>
              <p className="hero-description">
                At the core of PRIME's innovative, client-focused culture is a
                team of diverse, driven, mostly millenial real estate
                professionals headed by leaders with over 80 years of combined
                real estate experience.
              </p>
            </Col>
          </Row>
        </StyledContentArea>

        <StyledVideoPlayer>
          <Player
            poster="../../images/about-us/leadership-mobile-cover.jpg"
            muted
            autoPlay
            loop
            playsInline
          >
            <source src="../../videos/leadership-header.webm" />
            <ControlBar disableCompletely />
          </Player>
        </StyledVideoPlayer>
      </div>

      {/* <Row type="flex" justify="center" style={{ marginTop: -30 }}>
        <Col xs={22} sm={22} md={22} lg={20} xl={18} xxl={12}>
          <Row className="hero-cta" align="stretch" justify="center">
            <Col
              className="hero-cta-rebic"
              xs={24}
              sm={24}
              md={18}
              lg={18}
              xl={18}
              xxl={18}
            >
              {data.allStrapiHeroFeature.nodes[0].title}
              <OutboundLink
                href={data.allStrapiHeroFeature.nodes[0].link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.allStrapiHeroFeature.nodes[0].description}
              </OutboundLink>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Link className="hero-cta-covid" to="/our-covid-19-response">
                OUR COVID-19 RESPONSE <FontAwesomeIcon icon="angle-right" />
              </Link>
            </Col>
          </Row>
        </Col>
      </Row> */}
    </VideoBackgroundHeaderWrapper>
  );
}

export default VideoHeaderBackgroundComponent;
