import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  .bod-bio {
    overflow: hidden;
    font-size: 16px;
    line-height: 20px;
    position: relative;
    margin-top: 16px;
    &.collapsed {
      max-height: 140px;
      mask-image: linear-gradient(to bottom, black 60%, transparent 100%);
    }
  }
  .bod-bio-read-more {
    margin-top: 8px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 24px;
  }
`;

function MobileBioComponent({ render }) {
  const [showMore, setShowMore] = useState(false);
  return (
    <Wrapper>
      <div
        className={`bod-bio ${!showMore ? 'collapsed' : ''}`}
        dangerouslySetInnerHTML={{
          __html: render,
        }}
      />
      <div className="bod-bio-read-more" onClick={() => setShowMore(!showMore)}>
        {!showMore ? 'READ MORE' : 'HIDE'}
      </div>
    </Wrapper>
  );
}

MobileBioComponent.propTypes = {
  render: PropTypes.string,
};

export default MobileBioComponent;
